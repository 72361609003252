import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import StartPage from "./Pages/StartPage/StartPage";
import OverviewPage from "./Pages/OverviewPage/OverviewPage";
import DetailsPage from "./Pages/OverviewPage/DetailsPage";
import CustomisePage from "./Pages/OverviewPage/CustomisePage";
import CheckoutPage from "./Pages/CheckoutPage/CheckoutPage";
import AboutPage from "./Pages/AboutPage/AboutPage";

const rootEl = document.getElementById("root");

if (rootEl) {
  const root = ReactDOM.createRoot(rootEl);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <StartPage />,
    },
    {
      path: "/overview",
      element: <OverviewPage />,
    },
    {
      path: "/details/:slug",
      element: <DetailsPage />,
    },
    {
      path: "/customise/:slug",
      element: <CustomisePage />,
    },
    {
      path: "/checkout",
      element: <CheckoutPage />,
    },
    {
      path: "/design",
      element: <App />,
    },
    {
      path: "/about",
      element: <AboutPage />,
    },
  ]);
  root.render(<RouterProvider router={router} />);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
