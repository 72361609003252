import styled from "styled-components";

type Props = {
  title: string;
  image?: HTMLImageElement;
};

type SlimHeroProps = {
  bg: string;
};

const StyledHeader = styled.h2`
  margin: 0;
`;

const StyledSlimHero = styled.div<SlimHeroProps>`
  width: 100%;
  text-align: center;
  background: url(${(props) => props.bg}) no-repeat center;
  background-color: var(--black);
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  color: var(--white);
  text-shadow: var(--black) 1px 0 10px;
  background-size: 100% auto;
  animation: bgZoom 180s infinite;

  @keyframes bgZoom {
    50% {
      background-size: 130% auto;
    }
  }
`;

const SlimHero = ({ title = "", image }: Props) => (
  <StyledSlimHero bg={image as unknown as string}>
    <StyledHeader>{title}</StyledHeader>
  </StyledSlimHero>
);

export default SlimHero;
