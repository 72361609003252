import styled from "styled-components";

type Props = {
  className?: string;
  image: string;
  width?: number;
  height?: number;
};

type DigitalPreviewProps = {
  img: string;
};

const StyledDigitalPreview = styled.div<DigitalPreviewProps>`
  background: url(${(props) => props.img}) center no-repeat;
  background-color: var(--black);
  background-size: 100% auto;
  width: 300px;
  height: 400px;
  border-radius: 15px;
  position: relative;
  text-align: center;
`;

const StyledLabel = styled.div`
  position: absolute;
  border-radius: 10px;
  border: 3px solid var(--blue);
  background-color: var(--white);
  color: var(--blue);
  font-size: 48px;
  padding: 20px;
  padding-bottom: 0;
  bottom: 10%;
  left: -45px;
`;

const StyledSize = styled.p`
  color: var(--black);
  margin-bottom: 0;
  padding-bottom: 0;
`;

const StyledTimesText = styled.span`
  font-size: 12px;
  position: relative;
  top: -3px;
`;

const DigitalPreview = ({ className, image, width, height }: Props) => (
  <StyledDigitalPreview className={className} img={image}>
    <StyledLabel>
      JPEG
      {width && height && (
        <StyledSize>
          {width} <StyledTimesText>x</StyledTimesText> {height} px
        </StyledSize>
      )}
    </StyledLabel>
  </StyledDigitalPreview>
);

export default DigitalPreview;
